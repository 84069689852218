<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{}">
      <v-btn
        v-if="visible"
        @click="open(type_prop)"
        style="
          height: 30px;
          padding-right: 2px !important;
          padding-left: 2px !important;
        "
        elevation="0"
        block
        v-text="$t('configuration')"
        :disabled="!$store.getters['auth/googleAccounts']"
      />
    </template>
    <v-card>
      <v-card-title>{{ type }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <!--
        <v-row>
          <v-col>{{ tattooers }}</v-col>
        </v-row>
        -->
        <!--
          <v-row v-if="$store.getters['auth/googleAccounts'].length > 1">
          <v-col>
            {{ $t("calendar_default") }}
          </v-col>
          <v-col>
            <v-select
              outlined
              dense
              hide-details
              single-line
              item-text="user.email"
              item-value="user.email"
              :items="$store.getters['auth/googleAccounts']"
              v-model="calendar_id"
              @change="fetchCalendars"
            ></v-select>
          </v-col>
        </v-row>
        -->
        <v-row v-for="(t, i) in tattooers" :key="i" align="center">
          <v-col class="py-0">{{ t.name }} </v-col>

          <v-col class="py-0" cols="6">
            <v-autocomplete
              :items="calendars"
              outlined
              dense
              hide-details
              single-line
              clearable
              v-model="t.calendar_id"
              item-value="id"
            >
              <template v-slot:selection="{ item }">
                <v-icon :color="item.color" class="mr-1" v-if="item.id === -1">
                  mdi-close-circle
                </v-icon>
                <v-icon :color="item.color" class="mr-1" v-else>
                  mdi-circle
                </v-icon>
                {{ item.name }}
              </template>
              <template v-slot:item="{ item }">
                <v-icon :color="item.color" class="mr-1" v-if="item.id === -1">
                  mdi-close-circle
                </v-icon>
                <v-icon :color="item.color" class="mr-1" v-else>
                  mdi-circle
                </v-icon>
                {{ item.name }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-0"><v-divider /></v-col>
        </v-row>
        <v-row v-if="tattooers.length === 0">
          <v-col>No hay artistas para configurar la sincronización</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="cancel"
          outlined
          style="width: 100px !important; height: 30px"
        >
          {{ $t("back") }}
        </v-btn>
        <v-btn
          color="primary"
          style="
            width: 100px !important;
            color: #363533;
            height: 30px;
            padding-top: 10px !important;
          "
          elevation="0"
          @click="save"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: "" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CalendarConfiguration",
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    type_prop: {
      type: String,
      default: () => "gmail",
    },
  },
  data() {
    return {
      dialog: false,
      type: null,
      calendars: [],
      tattooers: [],
      old_tattooers: [],
      calendar_id: null,
    };
  },
  computed: {
    account_id() {
      switch (this.type) {
        case "gmail":
          return 1;
      }
    },
    account_name() {
      switch (this.type) {
        case "gmail":
          return "Google";
      }
    },
  },
  methods: {
    open(type) {
      if (
        this.$store.getters["auth/getSetting"](
          "calendar_relations_configurated"
        )
      )
        this.$alert(this.$t("calendar_configuration_already_done"));
      else {
        this.dialog = true;
        this.type = type;
        this.fetchCalendars();
        this.fetchStudioTattooers();
      }
    },
    ...mapActions("calendar", ["getCalendars"]),
    fetchCalendars() {
      ////console.log("fetch");
      this.getCalendars({
        account: this.account_id,
      }).then((data) => {
        console.log(data);
        this.calendars = [
          { id: -1, name: this.$t("calendar_no_sincro"), color: "red" },
          ...data["Google"],
        ];
      });
    },
    ...mapActions("studios", ["getStudioTattooersByDate"]),
    fetchStudioTattooers() {
      let date = new Date();
      this.getStudioTattooersByDate({
        studio_id: this.$store.state.auth.user.studio.id,
        date: date,
      }).then((data) => {
        this.tattooers = data.map((t) => {
          let name = t.tattooer.user.first_name;
          if (t.tattooer.user.last_name)
            name += " " + t.tattooer.user.last_name;
          let calendar_id = null;
          if (this.$store.getters["auth/isStudio"])
            calendar_id = t.studio_calendar_google;
          if (this.$store.getters["auth/isTattooer"])
            calendar_id = t.tattooer_calendar_google;
          return { name, calendar_id, id: t.tattooer_id };
        });
      });
    },
    ...mapActions("oauth", ["configurateCalendars"]),
    save() {
      this.$confirm(this.$t("ask_calendar_configuration")).then(() => {
        console.log("go");
        this.tattooers.forEach((t, i) => {
          let c = this.calendars.find((c) => c.id === t.calendar_id);
          if (!c) this.tattooers[i].calendar_id = null;
        });
        this.configurateCalendars({
          relations: this.tattooers,
          calendar_id: this.calendar_id,
        }).then(() => {
          this.dialog = false;
          this.$alert(this.$t("save_ok"));
        });
      });
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>